import Layout from '../../components/layout'
import React from 'react'
import SEO from '../../components/seo'
import ServiceHeader from '../../components/sections/ServiceHeader'
import { graphql } from 'gatsby'
import { StaticImage,GatsbyImage,getImage  } from 'gatsby-plugin-image'
import HeadingContentIconBoxes from '../../components/sections/HeadingContentIconBoxes'
import { default as SbsIHCB } from '../../components/sections/SidebysideImageHeadingContentButton'
import CaseStudy from '../../components/sections/CaseStudy'
//import SwiperAccordion from '../../components/SwiperAccordion'
import CallToAction from '../../components/sections/CallToAction'
//import FAQ from '../../components/sections/Faq'
export default function SeoPage(props) {
  const featuredWork = props.data.featuredWork.edges[0].node;
  const featuredWorkImage = getImage(featuredWork.banner)
  return (
    <>
      <Layout>
        <SEO
          title="Social Media Marketing Kuala Lumpur | Social Media Marketing Agency | Majiska"
          description="Leading social media marketing company Kuala Lumpur Malaysia. Our point-to-point action ensures that you make the best digital marketing decisions for your business."
          isCollectionPage={true}
          about={{ type: 'service' }}
          service={{
            name: 'Social Media Marketing  (SMM)',
            sameAs: 'https://www.wikidata.org/wiki/Q267114',
            description: 'Strategic publicity, not yelling.'
          }}
          
        />
        <main className="">
          <ServiceHeader
            heading="Social Media Marketing"
            subheading="Strategic publicity, not yelling."
            list={[
              {
                iconName: 'handshake',
                description:
                  "We are your digital success partner that takes care of your business growth",
              },
              {
                iconName: 'brain',
                description:
                  "7 of Malaysia's leading digital performance specialists in one local agency.",
              },
              {
                iconName: 'strategy-action',
                description:
                  "Trusted by Malaysia's leading brands, but proudly partnering many smaller ones with big aspirations",
              },
              {
                iconName: 'certified',
                description:
                  "Kuala Lumpur’s leading independent Facebook Partner (accredited by Facebook).",
              },
            ]}
            image={
              <StaticImage src="../../images/malaysia-top-social-media-marketing-expert.png" alt="A dinosaur" />
            }
            isImageSquare
          />

          <HeadingContentIconBoxes
            heading="Why Majiska for Social Media Marketing?"
            content={`<p>We go through rigors research and testing just like any other digital marketing effort, which means that our approach to social media is extremely systematic.</p>
            <p>Our data-driven approach is about more than just fast wins and traffic.</p>
            <p>Our team in Kuala Lumpur formulates and constantly optimises it to produce the bottom-line results you need.</p>`}
            iconBoxStyle={2}
            iconBoxObj={[
              {
                name: 'Facebook',
                description:
                  "With over 140 million companies and over 2 billion monthly active users, Facebook is the world's largest social networking site.With a comprehensive Facebook marketing plan, we will insure your Facebook advertising is efficient",
                iconName: 'facebook',
              },
              {
                name: 'Instagram',
                description:
                  'About 1 billion monthly active users, Instagram users, second only to Facebook. We target your ideal customers with an effective social media marketing campaign.',
                iconName: 'instagram',
              },
              {
                name: 'Linked-In',
                description:
                  "LinkedIn is the world's most popular professional networking site, with over 700 million users. We will assist you in designing a successful LinkedIn marketing plan to reach B2B decision makers.",
                iconName: 'linkedin',
              }
            ]}
            padding="py-0"
          />
          <SbsIHCB
            heading="Home to the best social media advertising specialists."
            content={
              <p>
                Any business that advertises on social media platforms has the ability to invest in technology. Technology is just as successful as the people who make it work for it. At our Kuala Lumpur headquarters, we're constantly building up both our team and our technology. Unwaveringly. The very best social media experts. With the access to the latest technologies.
              </p>
            }
            image={
              <StaticImage src="../../images/malaysia-top-digital-marketing-specialists.png" alt="A dinosaur" />
            }
            isEven
            isImageSquare
            padding={`py-0`}
          />
          <HeadingContentIconBoxes
            heading="Our approach to social media advertising."
            content={`Fine-tuned methodology + Loveable specialists + thorough analysis + specialized tools. This is the fundamental principle for our social media marketing services success. It allows us as a performance first digital marketing agency in making an accurate, consistent first impression on target audience members through well-crafted messaging that is perceived as valuable by potential customers. This will boost your SEO campaign if you're trying to get found on page one, to boost rankings, help the company with business goals, or promote your brand.`}
            iconBoxStyle={3}
            iconBoxObj={[
              {
                name: 'Target Market',
                description:
                  'We conduct market research on your business, consumers, and competitors to identify your ideal target audience.',
                iconName: 'audience',
              },
              {
                name: 'Best Practice Optimisation',
                description:
                  'keeping up to date on platform changes helps us to reduce lead costs while increasing its effectiveness.',
                iconName: 'architecture',
              },
              {
                name: 'Creative and Messaging',
                description:
                  'We use branding elements and messaging to create compelling advertising copy that results in sales.',
                iconName: 'message',
              },
              {
                name: 'Advanced Reporting Techniques',
                description:
                  'We produce dynamic Ads that serve the most effective advertisements to the appropriate audiences.',
                iconName: 'measure',
              },
              {
                name: 'Advanced Targeting',
                description:
                  'We can insure optimum audience matching using modern tech such as lookalike audiences and pixels.',
                iconName: 'targeting',
              },
              {
                name: 'Multi-Channel Campaigns',
                description:
                  'When used along with digital marketing strategies, social media will achieve its true potential',
                iconName: 'multichannel',
              },
              {
                name: 'Strategy',
                description:
                  'A solid organic search strategy requires a good content that taps into the heart of your customers. Our content writers specialize in emphatic writing that uses your brand tone.',
                iconName: 'strategy',
              },
           
            ]}
          />
          <CaseStudy
            title={featuredWork.title}
            content={featuredWork.description}
            image={
              <GatsbyImage image={featuredWorkImage} className="stats__fimage" alt={`case study of ${featuredWork.title}`} />
            }
            to={`/work/${featuredWork.slug}`}
            stats={featuredWork.stats}
          />
          <SbsIHCB
            heading="Transparency and accountability."
            content={
              <>
                <h3>
                  We make no attempt to hide ourselves behind social media metrics.
                </h3>
                <p className="">
                  Our agency's social media analytics experts use different metrics in order to evaluate campaign effectiveness and success. But all that really matters is the ROI, and your bottom line. That, you can leave them to us.
                </p>
              </>
            }
            image={
              <StaticImage src="../../images/malaysia-best-digital-marketing-company.png" alt="A dinosaur" />
            }
            isImageSquare
            isEven
            padding="py-0"
          />
          <SbsIHCB
            heading="The benefits of smart social media advertising."
            content={
              <>
                <p>
                  A business's Facebook, Twitter, and other social media advertising campaign can be created by any marketing agency. With our data-driven approach and experienced consultants, we craft data-backed plans and winning campaigns that bring us real results. Observable outcomes In terms of the bottom line</p>
                <ul>
                  <li>Persuade on every social media site</li>
                  <li>Boost brand recognition and traffic on your website</li>
                  <li>Attract highly specific audiences</li>
                  <li>Benefit from your advertising investment to the fullest extent possible.</li>
                  <li>Easily integrate with other forms of digital marketing</li>

                </ul>
              </>
            }
            isImageSquare
            image={
              <StaticImage src="../../images/malaysia-digital-marketing-strategy-partner.png" alt="A dinosaur" />
            }
            padding="py-0"
          />
          <SbsIHCB
            className={``}
            heading={
              <>
                Choosing a reputable social advertising partner.

              </>
            }
            content={
              <>
                <p>
                  Social media ads will help you build brand recognition, increase website traffic, and ultimately boost your bottom line. And if they aren't managed correctly, it can be an immense waste of time and marketing budget. Consider the following when hiring a social media advertising firm or consultant:</p>
              </>
            }
           
            isFull={true}
          />
          {/* <SwiperAccordion

            content={[
              {
                name: 'COOL',
                content:
                  '<p>Sed augue ipsum,<br> egestas nec, vestibulum et, malesuada adipiscing, dui. Phasellus dolor. Vivamus elementum semper nisi.</p>',
                image: 'imageSEO',
              },
              {
                name: 'Digital Advertising',
                content:
                  'Vivamus euismod mauris. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Praesent congue erat at massa.',
                image: 'imagePPC',
              },
              {
                name: 'Google Ads',
                content:
                  'Aliquam eu nunc. Donec sodales sagittis magna. Nunc sed turpis. Vivamus quis mi.',
                image: 'imageSocial',
              },
              {
                name: 'Remarketing',
                content:
                  'VEtiam ut purus mattis mauris sodales aliquam. Mauris turpis nunc, blandit et, volutpat molestie, porta ut, ligula. Nam commodo suscipit quam. Aliquam lobortis.',
                image: 'imageRemarketing',
              },
            ]}
            className={`pb-resp-16-32 bg-theme`}
          /> */}
          <HeadingContentIconBoxes
            heading="Are we the right social ad agency for you?"
            content={`In addition to having clients of different sizes throughout Malaysia, we have numerous social media experts. This puts us in a great position to provide and social media services for companies of all sizes. And most importantly, we can demonstrate it.`}
            iconBoxStyle={3}
            iconBoxObj={[
              {
                name: 'Micro',
                description:
                  'Our smaller clients account for 28% of businesses with one to ten staff and are widely regarded as having a growth mentality.',
                iconName: 'micro',
              },
              {
                name: 'Small',
                description:
                  'We identify 25% of our clients as small companies with 11–50 employees.',
                iconName: 'small',
              },
              {
                name: 'Medium',
                description:
                  'We identify 25% of our clients as medium-sized firms with 50–100 employees.',
                iconName: 'medium',
              },
              {
                name: 'Enterprise',
                description:
                  '22% of our customers are larger companies with more than 100 employees.',
                iconName: 'enterprise',
              }
            ]}
            padding="py-0"
          />
         
          <CallToAction />
        </main>
      </Layout>
    </>
  )
}


export const pageQuery = graphql`
{
  featuredWork: allContentfulPastWorks(
    filter:{markAsFeatured: {eq: true}}
  ) {
    edges {
      node {
        id
        title
        slug
        description
        date: createdAt(formatString: "MMMM DD, YYYY")
        banner: bannerImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        stats{
          number,
          description,
        }
      }
    }
  }
}
`
